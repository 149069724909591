import {Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {Result} from '@zxing/library';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit {

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  resultString: string;
  result: Result;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;

  constructor(private router: Router) {
  }

  ngOnInit(): void {

    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasDevices = true;
      this.availableDevices = devices;

      // selects the devices's back camera by default
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          this.scanner.changeDevice(device);
          this.currentDevice = device;
          break;
        }
      }
    });

    this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.scanComplete.subscribe((result: Result) => this.result = result);
    this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  /**

   * Checks, if scanned code is a possible ID (numbers). If yes, it will redirect to the editor.
   * @param resultString - message behind the code
   */
  handleCodeResult(resultString: string) {
    this.resultString = resultString;
    const regex = /^[0-9]*$/g;
    if (regex.test(resultString)) {
      const resultNumber = parseInt(resultString, 0);
      return this.router.navigateByUrl('item-editor-mobile/' + resultNumber);
    } else {
      alert('The scanned code is a number.\n\nPlease scan again or present a viable code!');
    }

  }

  /**
   * changes the selected device(camera) if another is selected
   * @param selectedValue - Stringrepresentation of the selected Camera
   */
  onDeviceSelectChange(selectedValue: string) {
    this.currentDevice = this.scanner.getDeviceById(selectedValue);
  }

  stateToEmoji(state: boolean): string {

    const states = {
      // not checked
      undefined: '❔',
      // failed to check
      null: '⭕',
      // success
      true: '✔',
      // can't touch that
      false: '❌'
    };
    return states['' + state];
  }
}

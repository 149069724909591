import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SheetService} from '../sheet.service';
import {UserService} from '../user.service';
import {columnProperties} from './column-properties';
import {catchError} from 'rxjs/operators';
import {ColumnProperty} from './column-property-interface';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';

export interface RowObject {
  __rowId: number;
}

@Component({
  selector: 'app-mobile-editor',
  templateUrl: './mobile-editor.component.html',
  styleUrls: ['./mobile-editor.component.css']
})

export class MobileEditorComponent implements OnInit {

  private spreadSheetId = '1bHXat45Ro6zTThwOP_W4iQIAeDyX5fr4kthrilTcJ80';
  private id: number;
  private idList: number[] = [];
  public rowObject: RowObject;
  private header: string[];
  public props: Array<ColumnProperty> = columnProperties;
  private autocompleteOptions: Object = {};
  private dateHeaders: String[] = [];
  private booleanHeaders: String[] = [];
  private rowObjectLoaded: Subject<Boolean> = new Subject<Boolean>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private sheetService: SheetService,
              private userService: UserService,
              private location: Location) {

  }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
          this.id = +params['id'];
          this.getRowsFromSpreadsheet();
        }
      )
    ;
  }

  /**
   * Calculates the column letter of spreadsheets for a given number
   * @returns columnLetter - returns column ID as letters i.e. 56 => BD
   */
  private async getRowsFromSpreadsheet() {
    const spreadsheet = await this.sheetService
      .findById(this.spreadSheetId, this.userService.getToken()).pipe(catchError(err => this.handleError(err))).toPromise();
    const parsedRows = spreadsheet.sheets[0].data[0].rowData;

    this.header = parsedRows[0].values.map(e => {
      return e.effectiveValue['stringValue'];
    });
    this.props.forEach(value => {
      if (value.type === 'autocompleteText') {
        this.autocompleteOptions[value.label] = [];
      } else if (value.type === 'date') {
        this.dateHeaders.push(value.label);
      } else if (value.type === 'boolean') {
        this.booleanHeaders.push(value.label);
      }
    });
    parsedRows.shift(); // remove header
    let rowNumber = 2;
    const rowsWithData = parsedRows
    // Maps over given IDs because of [0]! Without [0], all rows of entire sheet would be listed.
    // May collide with new entries, if ID is given by sheets own script
    // TODO: change filter
      .filter(e => e.values[0].effectiveValue);
    rowsWithData.map(rowObjectOfSheet => {
      const inventoryItem = {
        __rowId: rowNumber
      };
      this.header.forEach((value, index) => {

        if (rowObjectOfSheet.values.length > index) {
          if ('effectiveValue' in rowObjectOfSheet.values[index]) {
            if (rowObjectOfSheet.values[index].effectiveValue[Object.keys(rowObjectOfSheet.values[index].effectiveValue).pop()] != null) {
              inventoryItem[value] =
                rowObjectOfSheet.values[index].effectiveValue[Object.keys(rowObjectOfSheet.values[index].effectiveValue).pop()] || '';
              if (value in this.autocompleteOptions && inventoryItem[value].length > 0) {
                if (!this.autocompleteOptions[value].includes(rowObjectOfSheet
                  .values[index]
                  .effectiveValue[Object.keys(rowObjectOfSheet.values[index].effectiveValue).pop()])) {
                  this.autocompleteOptions[value].push(rowObjectOfSheet
                    .values[index]
                    .effectiveValue[Object.keys(rowObjectOfSheet.values[index].effectiveValue).pop()]);
                }
              } else if (this.dateHeaders.includes(value)) {
                /*
                 * Google Sheets date are a value representing so many milliseconds since 1900
                 * while typescript date are a value represeting so many milliseconds since 1970
                  */
                inventoryItem[value] = Number.isNaN(inventoryItem[value]) ? '' : new Date((inventoryItem[value] - 25569) * 86400000);
              } else if (this.booleanHeaders.includes(value)) {
                inventoryItem[value] = inventoryItem[value] === 'ja';
              }
            } else {
              inventoryItem[value] = '';
            }
          } else {
            inventoryItem[value] = '';
          }
        } else {
          inventoryItem[value] = '';
        }
      });
      rowNumber++;
      if (!isNaN(this.id) && !isNaN(inventoryItem['ID'])) {
        if (inventoryItem['ID'] === this.id) {
          this.rowObject = inventoryItem;
        }
      }
      this.idList.push(inventoryItem['ID']);
    });
    if (!this.rowObject) {
      this.rowObject = this.createEmptyRowObject(rowNumber);
    }
    this.rowObjectLoaded.next(true);

  }

  /**
   * Calculates the column letter of spreadsheets for a given number
   * @param rowNumber - column ID as number i.e. 56
   * @returns columnLetter - returns column ID as letters i.e. 56 => BD
   */
  private createEmptyRowObject(rowNumber) {
    if (isNaN(this.id)) {
      this.id = 10000 + rowNumber;
      while (this.idList.includes(this.id)) {
        this.id++;
      }
    }
    const inventoryItem = {
      __rowId: rowNumber
    };
    this.header.forEach((value) => {
      inventoryItem[value] = '';
    });
    inventoryItem['ID'] = this.id;
    this.location.go(this.route.url['value'][0].toString() + '/' + this.id.toString());
    return inventoryItem;
  }

  /**
   * Updates the given row object internally
   * @param value - value of a given label
   * @param headerName - corresponds to a given
   */
  private editRowObject(value: string, headerName: string) {
    this.rowObject[headerName] = value;
  }

  /**
   * Inserts the rowobject as Strings to the Spreadsheet. If successful, it will redirect to the scanner. If not, an error will be thrown.
   */
  public async saveRowObject() {
    let allFieldsFilled = true;
    this.props.forEach((prop) => {
      if (allFieldsFilled) {
        if (prop.mandatory) {
          if (!(this.rowObject[prop.label].toString().length > 0)) {
            alert('Required Fields are still empty!');
            allFieldsFilled = false;
          }
        }
      }
    });
    if (!allFieldsFilled) {
      return;
    }
    Object.keys(this.rowObject).forEach(key => this.toStringsInRowObjectFields(key));
    console.log(this.rowObject);
    const valueArrForSheetsService = this.header.map(headerCol => this.rowObject[headerCol]);
    const cells = 'A' + this.rowObject.__rowId + ':'
      + this.getColumnLetter(this.header.length) + this.rowObject.__rowId;
    await this.sheetService.updateCells(
      this.spreadSheetId, this.userService.getToken(), valueArrForSheetsService, cells)
      .pipe(catchError(err => this.handleError(err))).toPromise();
    alert('Your edit was saved!\n\nYou will be redirected to the scanner!');
    this.router.navigateByUrl('scanner');
  }

  /**
   * Converts all fields in the rowObject to strings.
   * This is necessary to push the content of the fields to the sheet (only strings are allowed)
   * @param rowObjectLabel - name of the column
   */
  private toStringsInRowObjectFields(rowObjectLabel) {
    if (this.dateHeaders.includes(rowObjectLabel)) {
      const dateToFormat: Date = this.rowObject[rowObjectLabel];
      this.rowObject[rowObjectLabel] = dateToFormat.getDate() + '.' +
        (Number(dateToFormat.getMonth()) + 1).toString() + '.' +
        dateToFormat.getFullYear();
    }
    if (typeof this.rowObject[rowObjectLabel] === 'boolean') {
      this.rowObject[rowObjectLabel] = this.rowObject[rowObjectLabel] ? 'ja' : 'nein';
    }
  }

  /**
   * Disables a date, if the linked condition (if it exists) isn't fulfilled.
   * @param rowObjectLabel - name of the column
   */
  private dateDisabled(rowObjectLabel) {
    const property: ColumnProperty = this.props.filter(value => value.label === rowObjectLabel)[0];
    if (property.conditional) {
      property.mandatory = !this.rowObject[property.conditionalTo];
      return !this.rowObject[property.conditionalTo];
    } else {
      return property['conditional'];
    }
  }

  /**
   * Disables checkbox if the linked condition (if it exists) isn't fulfilled.
   * @param rowObjectLabel - the name of the column
   */
  private checkboxDisabled(rowObjectLabel) {
    const property: ColumnProperty = this.props.filter(value => value.label === rowObjectLabel)[0];
    if (property.conditional) {
      property.mandatory = property.condition(this.rowObject[property.conditionalTo]);
      return !property.condition(this.rowObject[property.conditionalTo]);
    } else {
      return property['conditional'];
    }
  }


  /**
   * Returns an array of all strings, that are already listed in the given column(colLabel) and match the given string
   * @param colLabel - name of the column
   * @param value - the current user input
   */
  private filterAutocompleteOptions(colLabel, value) {
    return this.autocompleteOptions[colLabel].filter(option => option.toLowerCase().includes(value.toLowerCase()));
  }

  /**
   * Returns all possible options for a given select option. Those are listed in 'column-properties.ts'
   * @param rowObjectLabel - name of the column
   */
  private getSelectOptions(rowObjectLabel) {
    return this.props.filter(value => value.label === rowObjectLabel)[0].values;
  }


  /**
   * Calculates the column letter of spreadsheets for a given number
   * @param columnNumber - column ID as number i.e. 56
   * @returns columnLetter - returns column ID as letters i.e. 56 => BD
   */
  private getColumnLetter(columnNumber: number) {
    let dividend = columnNumber + 1;
    let columnName = '';
    let modulo: number;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }
    return columnName;
  }

  /**
   * Changes the color of a option to #949494 (gray), if it is disabled.
   * @param rowObjectLabel - name of the column
   */
  private changeColorDisabled(rowObjectLabel) {
    if (this.dateDisabled(rowObjectLabel)) {
      return '#949494';
    } else {
      return '#000000';
    }
  }

  /**
   * Typical error is that the user token has been expired. In the current scenario, the user will be
   * logged out, gets a message and will redirected to the welcome page. Error logs will be printed to console.
   * An error because of an invalid updateCell request will be handled with the same message (no differentiation)
   * Other errors won't be handled
   * @param err - system error message
   */
  private handleError(err) {
    this.userService.signOut();
    alert('Your session has expired!\n\nYou will be redirected to the welcome page!\n\n');
    return this.router.navigateByUrl('welcome');
  }

}

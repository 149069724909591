import {Component, ViewChild} from '@angular/core';
import {SheetService} from '../sheet.service';
import {UserService} from '../user.service';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

export interface RowObject {
  __rowId: number;
}

@Component({
  selector: 'app-item-editor',
  templateUrl: './item-editor.component.html',
  styleUrls: ['./item-editor.component.css']
})

export class ItemEditorComponent {

  spreadSheetId = '1bHXat45Ro6zTThwOP_W4iQIAeDyX5fr4kthrilTcJ80';
  displayedColumns: string[];
  dataSource: MatTableDataSource<Array<string>>;
  newRowSource: MatTableDataSource<Object>;
  header: string[];
  newRowHeader: string[];
  newRow: RowObject;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private sheetService: SheetService,
              private userService: UserService) {
    this.createTables();
  }

  /**
   * Creates a new table with data extracted for the given spreadsheet ID
   * and an empty table as a possible input for a new entry
   */
  private async createTables() {

    const spreadsheet = await this.sheetService
      .findById(this.spreadSheetId, this.userService.getToken()).toPromise();
    const parsedRows = spreadsheet.sheets[0].data[0].rowData;
    const header = this.header = parsedRows[0].values.map(e => {
      return e.formattedValue.split(/[\s\-\?\(\)\/]+/).join('');
    });

    let rowNumber = 1;
    if (parsedRows) {
      const formattedRows = parsedRows
      // Maps over given IDs because of [0]! Without [0], all rows of entire sheet would be listed.
      // May collide with new entries, if ID is given by sheets own script
      // TODO: change filter
        .filter(e => e.values[0].formattedValue)
        .map((rowObject) => {
          const inventoryItem = {
            __rowId: rowNumber
          };
          header.forEach((value, index) => {
            if (rowObject.values[index]) {
              if (rowObject.values[index].formattedValue != null) {
                inventoryItem[value] = rowObject.values[index].formattedValue || '';
              } else {
                inventoryItem[value] = '';
              }
            } else {
              inventoryItem[value] = '';
            }
          });
          rowNumber++;
          return inventoryItem;
        });

      const newRow = this.newRow = this.createNewRowTable(rowNumber);

      this.displayedColumns = header;
      formattedRows.splice(0, 1);
      this.dataSource = new MatTableDataSource(formattedRows);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.newRowSource = new MatTableDataSource([newRow]);
    }
  }

  /**
   * Creates a seperate Table for inputs to add a new row to the spreadsheet
   * @param rowNumber - the rowID where the row will be added if saveRow is executed
   */
  private createNewRowTable(rowNumber: number) {
    const warrantyHeader = 'Garantiebis';
    const newRowHeader = this.newRowHeader = this.header.slice(1);

    const newRow = this.newRow = {
      __rowId: rowNumber
    };
    newRowHeader.forEach((columnLabel) => {
      newRow[columnLabel] = '';
    });
    const sheetRowNum = rowNumber + 1;
    newRow[warrantyHeader] = `=IF(AND(ISDATE(J${sheetRowNum});K${sheetRowNum} = "ja"); J${sheetRowNum} + 365 * 2; "-")`;

    return newRow;
  }

  /**
   * Filters the output of the table to a given filter value
   * @param filterValue - input given by input form
   */
  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Updates the spreadsheet by sending new cell data to the spreadsheet.
   * @param rowObject - Contains data and row number (ID)
   * @param value - input of the form
   * @param headerName - needed to map to column ID
   */
  public editRow(rowObject: RowObject, value: string, headerName: string) {
    const colId = this.getColumnLetter(this.header.indexOf(headerName));
    const rowId = rowObject.__rowId;
    const cellId = colId + rowId;
    this.sheetService.updateCells(this.spreadSheetId, this.userService.getToken(), [value], cellId);
  }

  /**
   * Updates the newRow object internally
   * @param RowObject - Contains data and row number (ID)
   * @param value - input of the form
   * @param headerName - needed to map to column ID
   */
  private editNewRow(rowObject: RowObject, value: string, headerName: string) {
    rowObject[headerName] = value;
  }

  /**
   * Sends the values of the newRow object to the spreadsheet.
   */
  public saveNewRow() {
    const valueArrForSheetsService = this.newRowHeader.map(headerCol => this.newRow[headerCol]);
    const cells = 'B' + this.newRow.__rowId + ':'
      + this.getColumnLetter(this.header.length + 1) + this.newRow.__rowId;
    this.sheetService.updateCells(this.spreadSheetId, this.userService.getToken(), valueArrForSheetsService, cells);
    this.createTables();
  }

  /**
   * Calculates the column letter of spreadsheets for a given number
   * @param columnNumber - column ID as number i.e. 56
   * @returns columnLetter - returns column ID as letters i.e. 56 => BD
   */
  private getColumnLetter(columnNumber: number) {
    let dividend = columnNumber + 1;
    let columnName = '';
    let modulo: number;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }
    return columnName;
  }

}

import {Injectable, NgZone} from '@angular/core';
import * as _ from 'lodash';
import {GoogleAuthService} from 'ng-gapi/lib/GoogleAuthService';
import {Router} from '@angular/router';
import GoogleUser = gapi.auth2.GoogleUser;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public static readonly SESSION_STORAGE_KEY: string = 'accessToken';
  private user: GoogleUser = undefined;

  constructor(private googleAuthService: GoogleAuthService,
              private ngZone: NgZone,
              private router: Router) {
  }

  private setUser(user: GoogleUser): void {
    this.user = user;
  }

  private unsetUser(): void {
    this.user = undefined;
  }

  public getCurrentUser(): GoogleUser {
    return this.user;
  }

  public getToken(): string {
    const token: string = sessionStorage.getItem(UserService.SESSION_STORAGE_KEY);
    if (!token) {
      throw new Error('no token set , authentication required');
    }
    return sessionStorage.getItem(UserService.SESSION_STORAGE_KEY);
  }

  // TODO: consider piping catchError
  public signIn() {
    const googleAuth = this.googleAuthService.getAuth().toPromise();
    googleAuth.then(auth => {
      auth.signIn().then(res => this.signInSuccessHandler(res), err => this.signInErrorHandler(err));
    });
  }

  // TODO: consider piping catchError
  public signOut(): void {
    const googleAuth = this.googleAuthService.getAuth().toPromise();
    try {
      googleAuth.then(auth => {
        auth.signOut().then(res => this.signOutSuccessHandler(res), err => this.signOutErrorHandler(err));
      });
    } catch (e) {
      console.log(e);
    }
  }

  public isUserSignedIn(): boolean {
    return !_.isEmpty(sessionStorage.getItem(UserService.SESSION_STORAGE_KEY));
  }

  private signInSuccessHandler(res: GoogleUser) {
    this.setUser(res);
    sessionStorage.setItem(
      UserService.SESSION_STORAGE_KEY, res.getAuthResponse().access_token
    );
    return this.ngZone.run(() => this.router.navigateByUrl('scanner'));
  }

  private signOutSuccessHandler(res: GoogleUser) {
    this.unsetUser();
    sessionStorage.removeItem(UserService.SESSION_STORAGE_KEY);
    return this.ngZone.run(() => this.router.navigateByUrl('welcome'));
  }

  private signInErrorHandler(err) {
    console.warn(err);
  }

  private signOutErrorHandler(err) {
    console.warn(err);
    sessionStorage.removeItem(UserService.SESSION_STORAGE_KEY);
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {
  MatAutocompleteModule, MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';

import {ItemEditorComponent} from './item-editor/item-editor.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {ScannerComponent} from './scanner/scanner.component';
import {UserService} from './user.service';
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from 'ng-gapi';
import {WelcomeComponent} from './welcome/welcome.component';
import {MobileEditorComponent} from './mobile-editor/mobile-editor.component';
import {FormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {ZXingScannerModule} from '@zxing/ngx-scanner';


const gapiClientConfig: NgGapiClientConfig = {
  client_id: '106063087508-r0bf0ecnrp5sp861supnhb1l4s33mdkd.apps.googleusercontent.com',
  discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  scope: [
    'https://www.googleapis.com/auth/spreadsheets'
  ].join(' ')
};

@NgModule({
  declarations: [
    AppComponent,
    ItemEditorComponent,
    LoginComponent,
    WelcomeComponent,
    LoginComponent,
    MobileEditorComponent,
    ScannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatButtonModule,

    MatSelectModule,
    ZXingScannerModule.forRoot(),
    BrowserAnimationsModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [UserService, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule {
}

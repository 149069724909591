import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ItemEditorComponent} from './item-editor/item-editor.component';
import {AuthGuardService} from './auth-guard.service';
import {WelcomeComponent} from './welcome/welcome.component';
import {MobileEditorComponent} from './mobile-editor/mobile-editor.component';
import {ScannerComponent} from './scanner/scanner.component';

const routes: Routes = [
  {path: '', redirectTo: '/welcome', pathMatch: 'full'},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'scanner', component: ScannerComponent, canActivate: [AuthGuardService]},
  {path: 'item-editor', component: ItemEditorComponent, canActivate: [AuthGuardService]},
  {path: 'item-editor-mobile/:id', component: MobileEditorComponent, canActivate: [AuthGuardService]},
  {path: 'item-editor-mobile', component: MobileEditorComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class SheetService {
  private readonly API_URL = 'https://sheets.googleapis.com/v4/spreadsheets';

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Requests the data of a spreadsheet with a given ID
   * @param spreadsheetId - ID of the GoogleSpreadSheet
   * @param authtoken - token provided by GoogleSheetsApi, requiered to read/write to the given GoogleSpreadSheet
   * @returns spreadsheet - A JSON representation of the spreadsheet
   */
  public findById(spreadsheetId: string, authtoken: string): Observable<any> {
    return this.httpClient.get(this.API_URL + '/' + spreadsheetId, {
      params: {
        includeGridData: 'true'
      },
      headers: new HttpHeaders({
        Authorization: `Bearer ${authtoken}`,
      }),
    });
  }

  /**
   * Inserts values into a GoogleSpreadsheet with given ID
   * @param spreadsheetId - ID of the GoogleSpreadSheet
   * @param authtoken - token provided by GoogleSheetsApi, required to read/write to the given GoogleSpreadSheet
   * @param value - the values that should be inserted into the GoogleSpreadSheet
   * @param cells - range which cells will be updated
   */
  public updateCells(spreadsheetId: string, authtoken: string, value: string[], cells: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${authtoken}`,
      })
    };
    return this.httpClient.post(this.API_URL + '/' + spreadsheetId + '/values:batchUpdate', {
      'data': [
        {
          'range': cells,
          'values': [
            value
          ]
        }
      ],
      'valueInputOption': 'USER_ENTERED',
      'includeValuesInResponse': false,
      'responseDateTimeRenderOption': 'SERIAL_NUMBER',
      'responseValueRenderOption': 'FORMATTED_VALUE'
    }, httpOptions);
  }
}

import {ColumnProperty} from './column-property-interface';

/**
 * Labels have to be in order with header of the given spreadsheet. Original labels were formatted with
 * following expression: .split(/[\s\-\?\(\)\/]+/).join('')
 */
export const columnProperties: ColumnProperty[] =
  [
    {
      type: 'text',
      label: 'ID',
      mandatory: true,
      columnLetter: 'A',
      columnId: 1
    },
    {
      type: 'enum',
      label: 'Kategorie',
      mandatory: true,
      columnLetter: 'B',
      columnId: 2,
      values: ['IT-Hardware', 'Allgemein'],
    },
    {
      type: 'enum',
      label: 'Raum/Bereich',
      mandatory: true,
      columnLetter: 'C',
      columnId: 3,
      values: ['Storage', 'Room 4.01', 'Room 4.02', 'Room 4.03', 'Room 4.04', 'Room 4.05', 'Room 4.06', 'Room 4.07', 'Room 4.08',
        'Room 4.09', 'Room 4.1', 'Room 4.11', 'Room 4.12', 'Room 4.13', 'Room 4.14', 'Room 4.15', 'Room 4.16', 'Room 4.17', 'Room 4.18',
        'Room 4.19', 'Room 4.2', 'Room 4.21', 'Room 4.22', 'Room 4.23', 'Room 4.24', 'Room 4.25'],
    },
    {
      type: 'autocompleteText',
      label: 'Artikelbeschreibung',
      mandatory: true,
      columnLetter: 'D',
      columnId: 4
    },
    {
      type: 'enum',
      label: 'In Benutzung von',
      mandatory: false,
      columnLetter: 'E',
      columnId: 5,
      values: ['MH', 'MA', 'OH', 'AE', 'DW', 'SJ', 'AA', 'MW', 'PB', 'PW', 'TS', 'SG', 'DM', 'HH', 'EA', 'MHe', 'PF'],
    },
    {
      type: 'text',
      label: 'Hostname',
      mandatory: false,
      columnLetter: 'F',
      columnId: 6
    },
    {
      type: 'text',
      label: 'Serien-Nr.',
      mandatory: false,
      columnLetter: 'G',
      columnId: 7
    },
    {
      type: 'autocompleteText',
      label: 'Hersteller',
      mandatory: true,
      columnLetter: 'H',
      columnId: 8
    },
    {
      type: 'text',
      label: 'Typenbezeichnung',
      mandatory: true,
      columnLetter: 'I',
      columnId: 9
    },
    {
      type: 'date',
      label: 'Gekauft am',
      mandatory: true,
      columnLetter: 'J',
      columnId: 10,
      conditional: false,
    },
    {
      type: 'boolean',
      label: 'Garantie?',
      mandatory: true,
      columnLetter: 'K',
      columnId: 11,
      conditional: false,
    },
    {
      type: 'date',
      label: 'Garantie bis',
      mandatory: true,
      columnLetter: 'L',
      columnId: 12,
      conditional: true,
      conditionalTo: 'Garantie?',
    },
    {
      type: 'currency',
      label: 'Kaufwert (netto)',
      mandatory: true,
      columnLetter: 'M',
      columnId: 13
    },
    {
      type: 'autocompleteText',
      label: 'Wo gekauft',
      mandatory: true,
      columnLetter: 'N',
      columnId: 14
    },
    {
      type: 'enum',
      label: 'Zustand',
      mandatory: false,
      columnLetter: 'P',
      columnId: 15,
      values: ['Neu', 'Generalüberholt', 'Beschädigt', 'Defekt'],
    },
    {
      type: 'text',
      label: 'Ausstattung',
      mandatory: false,
      columnLetter: 'P',
      columnId: 16,
    },
    {
      type: 'boolean',
      label: 'Office',
      mandatory: true,
      columnLetter: 'Q',
      columnId: 17,
      conditional: true,
      conditionalTo: 'Artikelbeschreibung',
      condition: (value: string) => value === 'Laptop' || value === 'Computer',
    },
    {
      type: 'boolean',
      label: 'Tasche',
      mandatory: true,
      columnLetter: 'R',
      columnId: 18,
      conditional: true,
      conditionalTo: 'Artikelbeschreibung',
      condition: (value: string) => value === 'Laptop',
    },
    {
      type: 'boolean',
      label: 'Sichtschutz',
      mandatory: true,
      columnLetter: 'S',
      columnId: 19,
      conditional: true,
      conditionalTo: 'Artikelbeschreibung',
      condition: (value: string) => value === 'Laptop',
    },
    {
      type: 'boolean',
      label: 'Kensington',
      mandatory: true,
      columnLetter: 'T',
      columnId: 20,
      conditional: true,
      conditionalTo: 'Artikelbeschreibung',
      condition: (value: string) => value === 'Laptop',
    },
    {
      type: 'text',
      label: 'Kommentar',
      mandatory: false,
      columnLetter: 'U',
      columnId: 21
    }

  ];
